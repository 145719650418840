import { Flex, Spinner, useColorMode } from '@chakra-ui/react';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import darkFavicon from './assets/darkFavicon.ico';
import lightFavicon from './assets/lightFavicon.ico';

const Home = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./containers/Home')), 300);
  });
});

const App = () => {
  const [loading, setLoading] = useState(true);
  const { colorMode } = useColorMode();

  useEffect(() => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = colorMode === 'dark' ? darkFavicon : lightFavicon;
    document.getElementsByTagName('head')[0].appendChild(link);
  }, [colorMode]);

  useEffect(() => {
    // Add a contextmenu event listener when the component mounts
    window.addEventListener('contextmenu', handleRightClick);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  const handleRightClick = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  return (
    <BrowserRouter>
      {/* <ColorModeSwitcher /> */}
      <Suspense
        fallback={
          <Flex justify="center" align="center" height="80vh">
            <Spinner size="xl" />
          </Flex>
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              loading ? (
                <Flex justify="center" align="center" height="80vh">
                  <Spinner size="xl" />
                </Flex>
              ) : (
                <Home />
              )
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
