// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/helvetica-neue-55);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  font-family: "Quicksand", sans-serif !important;
}

* {
  -webkit-user-select: none;
          user-select: none;
}

::-webkit-scrollbar {
  width: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: #3f3f3f;
}

::-webkit-scrollbar-thumb {
  background-color: #3f3f3f;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #3f3f3f;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAIA;;EAEE,+CAAA;AAAF;;AAGA;EACE,yBAAA;UAAA,iBAAA;AAAF;;AAGA;EACE,WAAA;EACA,qDAAA;AAAF;;AAGA;EACE,uBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,yBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,yBAAA;EACA,mBAAA;EACA,6BAAA;EACA,4BAAA;AAAF;;AAGA;EACE,yBAAA;AAAF","sourcesContent":["@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');\n@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&display=swap');\n\nhtml,\nbody {\n  font-family: 'Quicksand', sans-serif !important;\n}\n\n* {\n  user-select: none;\n}\n\n::-webkit-scrollbar {\n  width: 20px;\n  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;\n}\n\n::-webkit-scrollbar-track {\n  background-color: white;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #3f3f3f;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #3f3f3f;\n  border-radius: 20px;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #3f3f3f;\n  border-radius: 20px;\n  border: 6px solid transparent;\n  background-clip: content-box;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background-color: #a8bbbf;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
